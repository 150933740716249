import { gql } from "@apollo/client";
export const GET_USER = gql`
  fragment getUser on User {
    _id
    name
    email
    avatar
    token
    clientType
    userType
    status
    company {
      _id
      type
      email
      remarks
      displayName
      depositAmount
    }
    roles {
      _id
      displayName
    }
  }
`;

export const GET_AUTH_USER = gql`
  fragment getAuthUser on User {
    _id
    name
    email
    avatar
    token
    clientType
    userType
    company {
      _id
      type
      email
      remarks
      displayName
      depositAmount
      nameAmharic
      managerNameAmharic
      header
      partners {
        _id
        type
        email
        displayName
      }
    }
    roles {
      _id
      displayName
    }
  }
`;
