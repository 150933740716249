import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { Button, Stack, TextField, Typography } from "@mui/material";

import Toast from "../../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { createUser, fetchRoles, getUser } from "../setupSlices";
import { changePageTitle } from "../../preferences/preferencesSlice";
import { selectSetups } from "../setupSlices";
import { UserRoles } from "./components/UserRoles";
import { Form, Formik, FormikProps } from "formik";
import { Save } from "@mui/icons-material";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { AuthUser, CreateUser, UserStatus } from "../../auth/types/authType";
import { createUserSchema } from "../validation";

interface UserProps {
  userId: string;
}
export const User = ({ userId }: UserProps) => {
  // const { id: userId } = useParams() as {
  //   id: string;
  // };

  const { selectedUser, error } = useAppSelector(selectSetups);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changePageTitle(`Manage User`));
    dispatch(fetchRoles("all"));
    // if (userId.length > 0) {
    //   dispatch(getUser(userId));
    // }
  }, [dispatch, userId]); //user

  useEffect(() => {
    // if (userId && roles.length > 0) {
    //   console.log(`from useEffect:${userId}`);
    //   dispatch(getUser(userId));
    // }
  }, [dispatch]);

  const EditUser = (name: string) => {
    dispatch(createUser({ ...selectedUser, name }));
  };

  return (
    <>
      <Helmet>
        <title> {`${selectedUser?.name} Account`}</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={selectedUser as CreateUser}
          validationSchema={createUserSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            values = { ...values, name: "changeEmail" };
            // console.log(
            //   values._id,
            //   values.email,
            //   values.password,
            //   values.status
            // );
            dispatch(createUser(values));
          }}
        >
          {(props: FormikProps<CreateUser>) => (
            <Form>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} md={7}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <FormikTextField formikKey="email" label="Email" />

                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!props.isValid}
                    >
                      Change Email
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={() => EditUser("reset")}
                  >
                    Reset Password
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  {selectedUser.status === UserStatus.Disabled ? (
                    <Button
                      fullWidth
                      color="success"
                      variant="contained"
                      onClick={() => EditUser("activate")}
                    >
                      Activate
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      onClick={() => EditUser("deactivate")}
                    >
                      Deactivate
                    </Button>
                  )}
                </Grid>
              </Grid>

              <br />
            </Form>
          )}
        </Formik>
        {/* <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              id="nameId"
              label="Name"
              value={selectedUser?.name as string}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textTransform: "uppercase" }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              id="emailId"
              label="Email"
              value={selectedUser?.email as string}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, textTransform: "uppercase" }}
            />
          </Grid>
        </Grid> */}

        <Accordion sx={{ my: 1 }}>
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" component="div">
              Roles
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <UserRoles userId={userId} />
          </AccordionDetails>
        </Accordion>

        {error && <Toast severity="error">{error.message}</Toast>}
      </Box>
    </>
  );
};
