import { gql } from "@apollo/client";
import { GET_AUTH_USER, GET_USER } from "../fragments/users";

export const SINGLE_UPLOAD_PROFILE = gql`
  mutation singleUpload($_id: String!, $type: PhotoTypes!, $avatar: Upload!) {
    uploadProfilePicture(input: { _id: $_id, type: $type, avatar: $avatar }) {
      _id
      type
      avatar
    }
  }
`;
export const SINGLE_UPLOAD_MUTATION = gql`
  mutation singleUpload($_id: String!, $type: PhotoTypes!, $avatar: Upload!) {
    uploadPicture(input: { _id: $_id, type: $type, avatar: $avatar }) {
      _id
      type
      avatar
    }
  }
`;
export const SIGN_IN = gql`
  ${GET_AUTH_USER}
  mutation Login($email: String!, $password: String!) {
    authUser(input: { email: $email, password: $password }) {
      ...getAuthUser
    }
  }
`;
export const SIGN_IN_CAPTCHA = gql`
  ${GET_AUTH_USER}
  mutation Login($email: String!, $password: String!, $captcha: String) {
    authUserCaptcha(
      input: { email: $email, password: $password, captcha: $captcha }
    ) {
      ...getAuthUser
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      _id
      name
      email
      avatar
    }
  }
`;
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword(
    $userId: String!
    $oldPassword: String!
    $password: String!
    $confirmPassword: String!
  ) {
    changePassword(
      input: {
        userId: $userId
        oldPassword: $oldPassword
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      _id
      name
      email
      avatar
    }
  }
`;
export const RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword(
    $id: String!
    $token: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetUserPassword(
      input: {
        id: $id
        token: $token
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      _id
      name
      email
      avatar
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($id: String!) {
    resendVerificationEmail(input: { id: $id }) {
      _id
      name
      email
      avatar
    }
  }
`;
export const VERIFY_EMAIL = gql`
  mutation VerifyEmail(
    $id: String!
    $token: String!
    $expires: String!
    $signature: String!
  ) {
    verifyEmail(
      input: {
        id: $id
        token: $token
        expires: $expires
        signature: $signature
      }
    ) {
      _id
      name
      email
      avatar
    }
  }
`;
export const SIGN_UP = gql`
  mutation SignUpUser(
    $email: String
    $name: String
    $password: String
    $confirmPassword: String
  ) {
    signUpUser(
      input: {
        email: $email
        name: $name
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      _id
      name
      email
      avatar
      token
      roles {
        _id
        displayName
      }
    }
  }
`;
export const SIGN_UP_CAPTCHA = gql`
  mutation RecaptchaRegister(
    $country: String
    $phone: String
    $name: String
    $email: String
    $message: String
    $captcha: String
  ) {
    recaptchaRegister(
      input: {
        email: $email
        name: $name
        country: $country
        phone: $phone
        message: $message
        captcha: $captcha
      }
    ) {
      _id
    }
  }
`;

export const CREATE_USER = gql`
  ${GET_USER}

  mutation CreateUser(
    $id: String
    $email: String
    $name: String
    $companyId: String
  ) {
    createUser(
      input: { id: $id, email: $email, name: $name, companyId: $companyId }
    ) {
      ...getUser
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeAUser($id: String!) {
    deleteUser(id: $id) {
      affectedRows
    }
  }
`;

export const SIGN_IN_GOOGLE = gql`
  ${GET_AUTH_USER}

  mutation Login($idToken: String!) {
    googleLogin(input: { idToken: $idToken }) {
      ...getAuthUser
    }
  }
`;

export const SIGN_IN_FACEBOOK = gql`
  ${GET_USER}

  mutation Login($userID: String!, $accessToken: String!) {
    facebookLogin(input: { userID: $userID, accessToken: $accessToken }) {
      ...getUser
    }
  }
`;

export const ADD_USER_ROLES = gql`
  ${GET_USER}

  mutation addUserRoles($ids: [String!]!) {
    addUserRoles(input: { ids: $ids }) {
      ...getUser
    }
  }
`;
export const ADD_USER_AGENCY = gql`
  ${GET_USER}

  mutation addUserAgency($ids: [Int!]!) {
    addUserAgency(input: { ids: $ids }) {
      ...getUser
    }
  }
`;
export const ADD_USER_AGENT = gql`
  ${GET_USER}

  mutation addUserAgent($ids: [Int!]!) {
    addUserAgent(input: { ids: $ids }) {
      ...getUser
    }
  }
`;
