import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { ContactMail, Drafts, MyLocation, Phone } from "@mui/icons-material";
import { Facebook, YouTube, Telegram, WhatsApp } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";

export default function Footer() {
  const theme = useTheme();
  const [isSudan, setIsSudan] = useState(false);

  // useEffect(() => {
  //   fetch("http://ip-api.com/json")
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       setIsSudan(payload.country === "sudan2");
  //     });
  // }, []);

  return (
    <Box id="contact" sx={{ pt: 8 }}>
      <Box
        // sx={{
        //   background: isSudan
        //     ? `linear-gradient(90deg, #D21034 65%, #FFFFFF 85%, #000000 95%)`
        //     : `linear-gradient(to right, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
        // }}
        sx={{ backgroundColor: theme.palette.primary.main }}
      >
        <Container maxWidth="sm">
          <Stack
            data-aos="fade-up"
            direction="row"
            sx={{ fontSize: "3rem" }}
            justifyContent="space-between"
            color="white"
          >
            <Link href="https://fa.com/enjazPlus" color="inherit">
              <Facebook />
            </Link>
            <Link href="https://youtube.com/enjazPlus" color="inherit">
              <YouTube />
            </Link>
            <Link href="https://t.me/enjazPlus" color="inherit">
              <Telegram />
            </Link>
            <Link href="https://whatsapp.com/enjazPlus" color="inherit">
              <WhatsApp />
            </Link>
          </Stack>
        </Container>

        <Container maxWidth="md" color="white">
          <Paper elevation={5} sx={{ background: "transparent" }}>
            <Stack
              data-aos="fade-up"
              spacing={0}
              sx={{ mt: 1 }}
              alignItems="center"
            >
              <Typography
                sx={{ mt: 1, color: "white" }}
                variant="h4"
                component="div"
                fontWeight="700"
              >
                <ContactMail />
                &nbsp;Contact
              </Typography>
              <Divider orientation="horizontal" flexItem />
              <Grid
                container
                direction="row"
                sx={{
                  width: "100%",
                  color: "white",
                }}
              >
                <Grid item md={4} xs={12}>
                  <ListItemButton component="a" href="#customized-list">
                    <ListItemIcon>
                      <Phone />
                    </ListItemIcon>
                    <ListItemText
                      primary="+251-911-850-908"
                      secondary="Mon-Sat 8am-6pm"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ListItemButton
                    component="a"
                    href="mailto:contact@enjazplus.com"
                  >
                    <ListItemIcon>
                      <Drafts />
                    </ListItemIcon>
                    <ListItemText
                      primary="contact@enjazplus.com"
                      secondary="Contact Us"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ListItemButton component="a" href="#customized-list">
                    <ListItemIcon>
                      <MyLocation />
                    </ListItemIcon>
                    <ListItemText
                      primary="Addis Ababa,Ethiopia"
                      secondary="Piasa Street, AA"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <Box>
            <Typography variant="body1" color="white" align="center">
              <>
                {"© "}
                <MuiLink
                  color="inherit"
                  href="https://pinnasofts.com/"
                  sx={{ textDecoration: "none" }}
                >
                  PinnaSofts PLC
                </MuiLink>{" "}
                {new Date().getFullYear()}.
              </>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
