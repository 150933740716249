import { NavLink as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Logo from "../features/process/components/Logo";
import Language from "./mode/Language";
import { useTranslation } from "react-i18next";
import { ShadowStyled } from "../features/home/styles/layoutStyled";
import { Height, Menu } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { MenuButton } from "../features/home/styles/componentStyled";
import React from "react";
//import { useAppDispatch } from "../app/hooks";
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const MainNavbar = () => {
  // const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation("auth");
  const { t: tt } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };
  // "https://api.ipregistry.co?key=ira_6NFfEDMgPpaTyStTHIUqSe9UlZpvpu0bbSX8"
  // console.log(
  //   payload.location.country.name + ", " + payload.location.city
  // );

  const [isSudan, setIsSudan] = useState(false);
  // useEffect(() => {
  //   fetch("http://ip-api.com/json")
  //     .then(function (response) {
  //       return response.json();
  //     })
  //     .then(function (payload) {
  //       setIsSudan(payload.country === "sudan2");
  //     });
  // }, []);

  return (
    <>
      <AppBar elevation={0}>
        <Box
          // sx={{
          //   background: isSudan
          //     ? theme.direction === "ltr"
          //       ? `linear-gradient(90deg, #D21034 35%, #FFFFFF 55%, #000000 95%)`
          //       : `linear-gradient(-90deg, #D21034 35%, #FFFFFF 55%, #000000 95%)`
          //     : theme.direction === "ltr"
          //     ? `linear-gradient(90deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`
          //     : `linear-gradient(-90deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
          // }}
          sx={{ backgroundColor: "transparent" }}
        >
          <ShadowStyled>
            <Toolbar sx={{ height: 64 }}>
              <Container maxWidth="xl">
                <Stack direction="row" alignItems="center">
                  <RouterLink to="/" data-aos="fade-right">
                    <Logo />
                  </RouterLink>
                  <Typography
                    data-aos="fade-left"
                    sx={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.light
                      ),
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      [theme.breakpoints.up("sm")]: {
                        fontSize: "1.5rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      [theme.breakpoints.up("md")]: {
                        fontSize: "2rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "2.2rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      lineHeight: "1.1",
                      p: 0.5,
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    {tt("platform")}
                  </Typography>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Stack
                    spacing={2}
                    sx={{
                      display: { md: "flex", xs: "none" },
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 0,
                        ml: 2,
                      },
                    }}
                    direction="row"
                  >
                    {/* <MenuButton href="/home">{t("home")}</MenuButton>
                    <MenuButton href="/ts">{tt("statusTracking")}</MenuButton> */}
                    <MenuButton href="/login">{t("signin")}</MenuButton>
                    <MenuButton href="/register">{t("signUp")}</MenuButton>
                    <Language />
                  </Stack>

                  <Stack sx={{ mr: 2, display: { md: "none", xs: "block" } }}>
                    <Box
                      style={{
                        borderRadius: "50%",
                      }}
                      onClick={handleDrawerOpen}
                    >
                      <Menu sx={{ width: "1.5em", height: "1.5em" }} />
                    </Box>
                  </Stack>
                </Stack>
              </Container>
            </Toolbar>
          </ShadowStyled>
        </Box>
      </AppBar>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        variant="temporary"
        onClose={handleDrawerOpen}
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        anchor="right"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        <Box
          sx={{
            // background: isSudan
            //   ? `linear-gradient(to bottom, #D21034 45%, #FFFFFF 65%, #000000 95%)`
            //   : `linear-gradient(to bottom, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
            backgroundColor: theme.palette.primary.main,
            minHeight: "100vh",
            overflow: "hidden",
          }}
        >
          <Toolbar>
            <RouterLink to="/" data-aos="fade-right">
              <Logo />
            </RouterLink>
            <Typography
              color="white"
              sx={{
                ml: "8px",
                color: theme.palette.getContrastText(
                  theme.palette.primary.light
                ),
                fontSize: "1.0rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                [theme.breakpoints.up("sm")]: {
                  fontSize: "1.2rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                [theme.breakpoints.up("md")]: {
                  fontSize: "2.2rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                [theme.breakpoints.up("lg")]: {
                  fontSize: "2.4rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                lineHeight: "1.1",
                p: 0.5,
                "&:hover": {
                  color: "white",
                },
              }}
            >
              {tt("platform")}
            </Typography>
          </Toolbar>
          <Divider />
          <List onClick={handleDrawerOpen}>
            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/home"
              >
                {t("home")}
              </MenuButton>
            </ListItem>
            {/* <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/ts"
              >
                {tt("statusTracking")}
              </MenuButton>
            </ListItem> */}
            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/login"
              >
                {t("signin")}
              </MenuButton>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/register"
              >
                {t("signUp")}
              </MenuButton>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                p: "6px 16px",
              }}
            >
              <Language />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default MainNavbar;
